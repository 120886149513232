import {ApplicationConfig, LOCALE_ID} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';
import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideStore} from '@ngrx/store';
import {effects, reducers, runtimeChecks} from "@store/state";
import {provideEffects} from "@ngrx/effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import { provideHttpClient } from "@angular/common/http";
import {authProviderConfig} from "@auth/auth.provider.config";
import {environment} from "@env/environment";

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: "fr-FR" },
    provideRouter(routes, withInMemoryScrolling({scrollPositionRestoration: "top", anchorScrolling: "enabled"})),
    provideAnimationsAsync(),
    provideStore(reducers, {runtimeChecks}),
    provideEffects(effects),
    provideStoreDevtools({maxAge: 25, logOnly: environment.production, autoPause: true}),
    provideHttpClient(),
    ...authProviderConfig
  ],
};
