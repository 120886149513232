import { FiltersDto } from '@dto/filters.dto';
import {
  NotificationDataByPages,
  NotificationPagination,
  Notifications,
} from '@store/notification-list/notification-list.reducer';
import { NotificationPage } from '@type/notifications.type';
import { getAllPages } from '@utils/notification-type-page.utils';

export const initFilters: FiltersDto = {
  sourceId: '',
  eventType: '',
  productId: '',
  receiver: '',
  date: '',
  status: '',
  notificationTypes: [],
};

export function getInitialNotificationPagination(): NotificationPagination {
  return {
    currentPageNumber: 1,
    totalNotifications: 0,
    totalPages: 1,
    pageSize: 10,
  };
}

export function initializePages(): NotificationDataByPages {
  const initNotification = {
    notification: {
      data: [],
      filters: initFilters,
      isLoading: false,
      error: null,
      reload: false,
    },
    notificationPagination: getInitialNotificationPagination(),
  };

  return getAllPages().reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: initNotification,
    }),
    {}
  ) as NotificationDataByPages;
}

export function updatePageNotification(
  initialValue: NotificationDataByPages,
  notificationPage: NotificationPage,
  partialNotification: Partial<Notifications>,
  partialNotificationPagination: Partial<NotificationPagination>
) {
  return {
    ...initialValue,
    [notificationPage]: {
      ...initialValue[notificationPage],
      notification: {
        ...initialValue[notificationPage].notification,
        ...partialNotification,
      },
      notificationPagination: {
        ...initialValue[notificationPage].notificationPagination,
        ...partialNotificationPagination,
      },
    },
  };
}

export function updateAllPageNotification(
  initialValue: NotificationDataByPages,
  partialNotification: Partial<Notifications>,
  partialNotificationPagination: Partial<NotificationPagination>
): NotificationDataByPages {
  return Object.entries(initialValue).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: {
        ...curr[1],
        notification: {
          ...curr[1].notification,
          ...partialNotification,
        },
        notificationPagination: {
          ...curr[1].notificationPagination,
          ...partialNotificationPagination,
        },
      },
    }),
    {}
  ) as NotificationDataByPages;
}
