import { Injectable } from '@angular/core';
import { notificationError } from '@components/error/error.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '@service/notification.service';
import { initFilters } from '@store/notification-list/notification-list.utils';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { getAllPages } from '@utils/notification-type-page.utils';
import {
  initAllNotificationPage,
  loadNotificationPage,
  loadNotificationPageFailure,
  loadNotificationPageSuccess,
} from './notification-list.action';
import { environment } from '@env/environment';

@Injectable()
export class NotificationListEffect {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}

  initNotificationPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAllNotificationPage),
      switchMap(_ =>
        getAllPages().map((notificationPage) =>
          loadNotificationPage({
            notificationPage,
            currentPageNumber: 1,
            filters: {
              ...initFilters,
              notificationTypes:
                environment.notificationPage[notificationPage]
                  .technicalNofTypes,
            },
            pageSize: 10,
            reload: false,
          })
        )
      )
    )
  );

  loadNotificationPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotificationPage),
      mergeMap(
        ({ notificationPage, currentPageNumber, filters, pageSize, reload }) =>
          this.notificationService
            .getNotificationList(currentPageNumber, filters, pageSize)
            .pipe(
              map((body) =>
                loadNotificationPageSuccess({
                  notificationPage,
                  notificationList: body,
                  reload: reload && currentPageNumber === 1,
                })
              ),
              catchError((error) =>
                of(
                  loadNotificationPageFailure({
                    notificationPage,
                    error: notificationError(error),
                  })
                )
              )
            )
      )
    )
  );
}
