import {createAction, props} from "@ngrx/store";
import {UserAuthorizationsDto} from "@dto/user-authorizations.dto";

const state = "[User Infos]";

export const initUserAuthorizations = createAction(
  `${state} Init user authorizations`,
)

export const initUserAuthorizationsSuccess = createAction(
  `${state} Init user authorizations success`,
  props<{ userAuthorizations: UserAuthorizationsDto }>()
)

export const initUserAuthorizationsFailure = createAction(
  `${state} Init user authorizations failure`,
  props<{ error: string }>()
)
