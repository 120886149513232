<ul class="button-menu">
  <li routerLinkActive="active" class="list-menu">
    <a routerLink="/dashboard" >
      <img class="icon" src="/assets/dashboard-logo.svg" alt="nof-icon">
      <span>Tableau de bord</span>
    </a>
  </li>
  <li routerLinkActive="active" class="list-menu dropDownHeader">
    <a class="disabled-ref">
      <img class="icon" src="/assets/notification-logo.png" alt="nof-icon">
      <span>Notifications</span>
    </a>
    @for (menuItem of notificationMenuList; track $index) {
      <div routerLinkActive="active" class="dropDownHeaderContent">
        <a [routerLink]="[menuItem.path]">
          <span>{{menuItem.name}}</span>
        </a>
      </div>
    }
  </li>
  @if (visible) {
    <li routerLinkActive="active" class="list-menu dropDownHeader">
      <a class="disabled-ref">
        <img class="icon" src="/assets/create-notif-logo.png" alt="nof-icon">
        <span>Créer ma notif</span>
      </a>
      <div routerLinkActive="active" class="dropDownHeaderContent">
        <a routerLink="/push/teams">
          <span>Teams</span>
        </a>
      </div>
    </li>
  }
</ul>
