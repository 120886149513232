import {NullableType} from "@type/nullable.type";
import {PieChartDto} from "@dto/pie-chart.dto";
import {createReducer, on} from "@ngrx/store";
import {
  changeFilters,
  changeFiltersFailure,
  changeFiltersSuccess,
  changeProductId,
  changeProductIdFailure,
  changeProductIdSuccess,
  initDashboard,
  initDashboardFailure,
  initDashboardSuccess,
  updateNotificationRtList
} from "@store/dashboard/dashboard.action";
import {ColumnChartDto} from "@dto/column-chart.dto";
import {ChartsParamsDto} from "@dto/charts-params.dto";
import {ChartPeriodType} from "@type/chart-period.type";
import {backendDateSerialize} from "@serializer/date.serializer";
import {NotificationDto} from "@dto/notification.dto";

export const initParams: ChartsParamsDto = {
  productId: '',
  eventType: '',
  monthsToDisplay: 1,
  toDate: backendDateSerialize(new Date()),
  allowedProductIdList: []
}

export interface ChartsState {
  eventTypeList: {data: string[], isLoading: boolean, error: NullableType<string>};
  filters: ChartsParamsDto;
  period: NullableType<ChartPeriodType>;
  pieCharts: { data: NullableType<PieChartDto>, isLoading: boolean, error: NullableType<string> };
  columnChart: { data: NullableType<ColumnChartDto>, isLoading: boolean, error: NullableType<string> };
  notificationList: NotificationDto[];
  rtHasNewNotification: boolean;
}

export const initialState: ChartsState = {
  eventTypeList: {data: [], isLoading: false, error: null},
  filters: initParams,
  notificationList: [],
  pieCharts: {data: null, isLoading: false, error: null},
  columnChart: {data: null, isLoading: false, error: null},
  period: null,
  rtHasNewNotification: false
}

export const dashboardReducer = createReducer(
  initialState,
  on(initDashboard, () => (
    {
      ...initialState,
      pieCharts: {...initialState.pieCharts, isLoading: true},
      columnChart: {...initialState.columnChart, isLoading: true},
    })
  ),

  on(initDashboardSuccess, (state, {charts}) => (
    {
      ...state,
      pieCharts: {...state.pieCharts, data: charts.pieChartsDto, isLoading: false},
      columnChart: {...state.columnChart, data: charts.columnChartDto, isLoading: false},
      period: {fromDate: charts.fromDate, toDate: charts.toDate},
      filters: {...initParams, allowedProductIdList: charts.allowedProductIdList},
    })
  ),

  on(initDashboardFailure, (state, {error}) => (
    {
      ...state,
      pieCharts: {...state.pieCharts, error, isLoading: false},
      columnChart: {...state.columnChart, error, isLoading: false},
    })
  ),

  on(changeProductId, (state) => (
    {
      ...state,
      eventTypeList: {...state.eventTypeList, isLoading: true},
    })
  ),

  on(changeProductIdSuccess, (state, {data}) => (
    {
      ...state,
      eventTypeList: {...state.eventTypeList, data, isLoading: false},
    })
  ),

  on(changeProductIdFailure, (state, {error}) => (
    {
      ...state,
      eventTypeList: {...state.eventTypeList, error, isLoading: false},
    })
  ),

  on(changeFilters, (state) => (
    {
      ...state,
      pieCharts: {...state.pieCharts, isLoading: true},
      columnChart: {...state.columnChart, isLoading: true},
    })
  ),

  on(changeFiltersSuccess, (state, {data, filters}) => (
    {
      ...state,
      pieCharts: {...state.pieCharts, data: data.pieChartsDto, isLoading: false},
      columnChart: {...state.columnChart, data: data.columnChartDto, isLoading: false},
      period: {fromDate: data.fromDate, toDate: data.toDate},
      filters: filters,
      rtHasNewNotification: false
    })
  ),

  on(changeFiltersFailure, (state, {error}) => (
    {
      ...state,
      pieCharts: {...state.pieCharts, error, isLoading: false},
      columnChart: {...state.columnChart, error, isLoading: false}
    })
  ),

  on(updateNotificationRtList, (state, {newNotification, toUpdate}) => {
    let newNotificationList = state.notificationList;

    if (newNotification) {
      newNotificationList = [newNotification, ...newNotificationList];
    }

    if (toUpdate) {
      let found = false;
      newNotificationList = newNotificationList.map(notification => {
        if (notification.sourceId === toUpdate.sourceId) {
          found = true;
          return {
            ...notification,
            state: toUpdate.state
          }
        }
        return notification;
      })

      if (!found) {
        newNotificationList = [toUpdate.notification, ...newNotificationList];
      } else {
        newNotificationList.sort((a, b) => {
          return new Date(b.state.date).getTime() - new Date(a.state.date).getTime();
        });
      }
    }

    if (newNotificationList.length > 10) {
      newNotificationList.pop();
    }

    return (
      {
        ...state,
        notificationList: newNotificationList,
        rtHasNewNotification: true,
      })
  }),
);
