import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./page/dashboard/dashboard.page').then(
        (m) => m.DashboardPageComponent
      ),
  },
  {
    path: 'notifications',
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: ':type',
        loadComponent: () =>
          import('./page/notification-list/notification-list.page').then(
            (m) => m.NotificationListPageComponent
          ),
      },
    ],
  },
  {
    path: 'notification-detail',
    loadComponent: () =>
      import('./page/notification-detail/notification-detail.page').then(
        (m) => m.NotificationDetailPageComponent
      ),
  },
  {
    path: 'push/teams',
    loadComponent: () =>
      import('./page/create-notif/create-notif.page').then(
        (m) => m.CreateNotifPageComponent
      ),
  },
];
