import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'tmn-header-bar-button',
  standalone: true,
  templateUrl: './header-button.component.html',
  styleUrl: './header-button.component.scss',
  imports: [RouterLink, RouterLinkActive],
})
export class HeaderBarButtonComponent {
  @Input() visible!: boolean;

  public notificationMenuList: { name: String; path: String }[] =
    Object.entries(environment.notificationPage).map(([path, obj]) => ({
      name: obj.name,
      path: `/notifications/${path}`,
    }));
}
