import { FiltersDto } from '@dto/filters.dto';
import { NotificationDto } from '@dto/notification.dto';
import { environment } from '@env/environment';
import { createReducer, on } from '@ngrx/store';
import {
  getInitialNotificationPagination,
  initializePages,
  updateAllPageNotification,
  updatePageNotification,
} from '@store/notification-list/notification-list.utils';
import { NotificationPage } from '@type/notifications.type';
import { NullableType } from '@type/nullable.type';
import {
  initAllNotificationPage,
  loadNotificationPage,
  loadNotificationPageFailure,
  loadNotificationPageSuccess,
  updateNotificationFromWs,
} from './notification-list.action';

export interface Notifications {
  data: NotificationDto[];
  filters: FiltersDto;
  isLoading: boolean;
  error: NullableType<string>;
  reload: boolean;
}
export interface NotificationPagination {
  currentPageNumber: number;
  totalNotifications: number;
  totalPages: number;
  pageSize: number;
}

export interface NotificationsPageData {
  notification: Notifications;
  notificationPagination: NotificationPagination;
}

export type NotificationDataByPages = {
  [key in NotificationPage]: NotificationsPageData;
};

export interface NotificationListState {
  pages: NotificationDataByPages;
}

export const initialState: NotificationListState = {
  pages: initializePages(),
};

export const notificationListReducer = createReducer(
  initialState,
  on(initAllNotificationPage, () => ({
    ...initialState,
    pages: updateAllPageNotification(
      initialState.pages,
      { isLoading: true },
      {}
    ),
  })),

  on(
    loadNotificationPage,
    (state, { notificationPage, currentPageNumber, filters, pageSize }) => ({
      ...state,
      pages: updatePageNotification(
        state.pages,
        notificationPage,
        {
          filters,
          isLoading: true,
        },
        {
          currentPageNumber,
          pageSize,
        }
      ),
    })
  ),

  on(
    loadNotificationPageSuccess,
    (state, { notificationPage, notificationList, reload }) => ({
      ...state,
      pages: updatePageNotification(
        state.pages,
        notificationPage,
        {
          data: notificationList.notifications,
          isLoading: false,
          error: null,
          reload,
        },
        {
          totalNotifications: notificationList.totalNotifications,
          totalPages: notificationList.totalPages,
        }
      ),
    })
  ),

  on(loadNotificationPageFailure, (state, { notificationPage, error }) => ({
    ...state,
    pages: updatePageNotification(
      state.pages,
      notificationPage,
      {
        error,
        isLoading: false,
      },
      getInitialNotificationPagination()
    ),
  })),

  on(updateNotificationFromWs, (state, { toUpdate }) => {
    const notificationPage: NullableType<NotificationPage> =
      Object.values(environment.notificationPage).find((nofPage) =>
        nofPage.technicalNofTypes.includes(
          toUpdate.notification.notificationType
        )
      )?.technicalName || null;

    if (notificationPage === null) {
      return state;
    }

    const updatedNotification = state.pages[
      notificationPage
    ].notification.data.map((notification) => {
      if (notification.sourceId === toUpdate.sourceId) {
        return { ...notification, state: toUpdate.state };
      }
      return notification;
    });

    return {
      ...state,
      pages: updatePageNotification(
        state.pages,
        notificationPage,
        { data: updatedNotification },
        {}
      ),
    };
  })
);
