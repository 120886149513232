import {Injectable} from "@angular/core";
import {NotificationService} from "@service/notification.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  initNotificationDetail,
  initNotificationDetailFailure,
  initNotificationDetailSuccess
} from "@store/notification-detail/notification-detail.action";
import {catchError, map, of, switchMap} from "rxjs";
import {notificationError} from "@components/error/error.utils";

@Injectable()
export class NotificationDetailEffect {
  constructor(public actions$: Actions, public service: NotificationService) {
  }

  initNotificationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initNotificationDetail),
      switchMap(({sourceId}) =>
        this.service.getNotificationDetail(sourceId).pipe(
          map(body => initNotificationDetailSuccess({data: body})),
          catchError((error) =>
            of(initNotificationDetailFailure({error: notificationError(error)}))
          )
        )
      )
    )
  )
}
