<div class="modal"
     *ngrxLet="((numberOfNewNotifications > 0 || disconnected) && isOnNotificationPage && isModalOpen) as visible"
     [style.opacity]="visible ? '1' : '0'"
     [style.visibility]="visible ? 'visible' : 'hidden'">
  <div class="info-bubble">
    <img src="/assets/info-solid.svg" alt="info">
  </div>
  <button class="cross-close" (click)="closeModal()">
    <img src="/assets/square-xmark-solid.svg" alt="close">
  </button>
  <div class="modal-content">
    <div>
      <span class="modal-title">Track'info</span>
      <div class="title-underline"></div>
    </div>
    @if (disconnected) {
      <span class="modal-text">
        Vous n'êtes pas connecté au réseau interne BPIFrance. Les fonctionnalités de mise à jour en temps réel sont désactivées.
      </span>
    } @else {
      <span class="modal-text">
        Vous avez {{ numberOfNewNotifications }} {{ numberOfNewNotifications | plural:"notification" }} en attente.
        Cliquez sur le bouton ci-dessous pour rafrachir la liste des notifications.
      </span>
      <button class="refresh" (click)="refreshNotification()">
        <img src="/assets/arrow-rotate-right-solid.svg" alt="refresh-arrow">
        <span>Rafraichir</span>
      </button>
    }
  </div>
</div>
