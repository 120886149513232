import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {BackgroundComponent} from '@components/background/background.component';
import {HeaderBarComponent} from '@components/header-bar/header-bar.component';
import {LetDirective} from '@ngrx/component';
import {Store} from '@ngrx/store';
import {WebsocketService} from '@service/websocket.service';
import {initDashboard} from '@store/dashboard/dashboard.action';
import {initAllNotificationPage} from '@store/notification-list/notification-list.action';
import {State} from '@store/state';
import {initUserAuthorizations} from '@store/user-infos/user-infos.action';
import {getIsUserAuthorizedToPushNotif, getUserAuthorizations,} from '@store/user-infos/user-infos.selector';
import {BehaviorSubject, filter, first, map, Observable, of} from 'rxjs';
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'tmn-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderBarComponent,
    LetDirective,
    BackgroundComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'TrackMaNotif';

  public numberOfNewNotification$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public disconnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasRight$: Observable<boolean> = this.store.select(getIsUserAuthorizedToPushNotif);
  public initApp$: Observable<void> = this.store.select(getUserAuthorizations).pipe(
    filter(
      (userAuthorizations) =>
        !userAuthorizations.isLoading && !userAuthorizations.error
    ),
    first(),
    switchMap(() => {
      this.store.dispatch(initAllNotificationPage());
      this.store.dispatch(initDashboard());
      return of(void 0)
    })
  )

  constructor(public store: Store<State>, public ws: WebsocketService, public titleService: Title) {
    registerLocaleData(localeFr, 'fr');
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    /*
    Temporary, disabled while I haven't found a solution for filtering the incoming notification from WebSockets
    this.ws.initWebSocket().then(() => {
      this.ws.subscribe("notification", (event) => {
            const wsMessage: WebsocketMessageDto = event.body;
            if (wsMessage.instruction === "UPDATE_NOTIFICATION") {
              const payload: WebsocketUpdateDetailDto = wsMessage.payload;
              this.store.dispatch(updateNotificationFromWs({ toUpdate: payload }));
              this.store.dispatch(updateNotificationDetail({ toUpdate: payload }));
              this.store.dispatch(updateNotificationRtList({
                  newNotification: null,
                  toUpdate: payload,
                }),
                );
            } else if (wsMessage.instruction === "UPDATE_LIST") {
              const payload: NotificationDto = wsMessage.payload;
              this.numberOfNewNotification$.next(this.numberOfNewNotification$.getValue() + 1);
              this.store.dispatch(updateNotificationRtList({
                  newNotification: payload,
                  toUpdate: null,
                }),
                );
            }
          })
      })
      .catch(() => {
        this.disconnected$.next(true);
        this.ws.disconnect();
      });*/

    this.store.dispatch(initUserAuthorizations());
  }

  refreshNotification() {
    this.numberOfNewNotification$.next(0);
  }

  ngOnDestroy() {
    this.ws.unsubscribeToWebSocketEvent('notification');
  }
}
