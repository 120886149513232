import {createReducer, on} from "@ngrx/store";
import {
  initUserAuthorizations,
  initUserAuthorizationsFailure,
  initUserAuthorizationsSuccess
} from "@store/user-infos/user-infos.action";
import {NullableType} from "@type/nullable.type";

export interface UserInfosState {
  userAuthorizations: {
    isUserAuthorizedToPushNotif: boolean,
    isLoading: boolean,
    error: NullableType<string>
  }
}

export const initialState: UserInfosState = {
  userAuthorizations: {
    isUserAuthorizedToPushNotif: false,
    isLoading: false,
    error: null
  }
};

export const userInfosReducer = createReducer(
  initialState,
  on(initUserAuthorizations, (state, _) => ({
    ...initialState,
    userAuthorizations: { ...initialState.userAuthorizations, isLoading: true },
  })),

  on(initUserAuthorizationsSuccess, (state, { userAuthorizations }) => ({
    ...state,
    userAuthorizations: {
      ...state.userAuthorizations,
      isUserAuthorizedToPushNotif:
        userAuthorizations.isUserAuthorizedToPushNotif,
      isLoading: false,
    },
  })),

  on(initUserAuthorizationsFailure, (state, { error }) => ({
    ...state,
    userAuthorizations: {
      ...state.userAuthorizations,
      error,
      isLoading: false,
    },
  }))
);
