import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  initUserAuthorizations,
  initUserAuthorizationsFailure,
  initUserAuthorizationsSuccess
} from "@store/user-infos/user-infos.action";
import {catchError, map, of, switchMap} from "rxjs";
import {UserService} from "@service/user.service";

@Injectable()
export class UserInfosEffect {
  constructor(public actions$: Actions, public userService: UserService) {
  }

  initUserAllowedProductIdList$ = createEffect(() => this.actions$.pipe(
    ofType(initUserAuthorizations),
    switchMap(_ => this.userService.getAllowedProductIdList().pipe(
      map(userAuthorizations => initUserAuthorizationsSuccess({userAuthorizations})),
      catchError((error) => of(initUserAuthorizationsFailure({error: error})))
    ))
  ))
}
