import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { PluralPipe } from '@pipe/plural.pipe';
import { loadNotificationPage } from '@store/notification-list/notification-list.action';
import { NotificationDataByPages } from '@store/notification-list/notification-list.reducer';
import { getNotifications } from '@store/notification-list/notification-list.selector';
import { State } from '@store/state';
import { NotificationPage } from '@type/notifications.type';
import { first } from 'rxjs';

@Component({
  selector: 'tmn-header-modal',
  standalone: true,
  templateUrl: './header-modal.component.html',
  styleUrl: './header-modal.component.scss',
  imports: [LetDirective, PluralPipe],
})
export class HeaderModalComponent {
  @Input() numberOfNewNotifications: number = 0;
  @Input() disconnected: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  public isModalOpen: boolean = true;
  public isOnNotificationPage: boolean =
    this.router.url.startsWith('/notifications');

  constructor(public router: Router, public store: Store<State>) {}

  refreshNotification() {
    this.store
      .select(getNotifications)
      .pipe(first())
      .subscribe((notificationPages: NotificationDataByPages) => {
        Object.entries(notificationPages).map(([page, data]) => {
          this.store.dispatch(
            loadNotificationPage({
              notificationPage: page as NotificationPage,
              currentPageNumber: data.notificationPagination.currentPageNumber,
              filters: data.notification.filters,
              pageSize: data.notificationPagination.pageSize,
              reload: true,
            })
          );
        });
      });

    this.close.emit();
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
