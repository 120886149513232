<ng-container *ngrxLet="initApp$">
  <tmn-header-bar
    *ngrxLet="{numberOfNewNotification: numberOfNewNotification$, disconnected: disconnected$, hasRight: hasRight$} as props"
    [numberOfNewNotifications]="props.numberOfNewNotification || 0"
    [disconnected]="props.disconnected"
    [visible]="props.hasRight"
    (close)="refreshNotification()"
  ></tmn-header-bar>
  <div class='router'>
    <tmn-background></tmn-background>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
